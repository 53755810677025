import React, {forwardRef} from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { listItems } from "../../constants/navitems";
import "./style.css"
import { CircleX, FilePen, LogOut } from "lucide-react";


const Sidebar = forwardRef(({ toggleSidebar, open}, ref) => {

    const navigate = useNavigate(); // Use useNavigate for navigation

    const currentRoute = useLocation();
    
    // const currentPathname = currentRoute.pathname.split('/')[1];

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear the token from local storage
    navigate('/login'); // Redirect to the login page
  };
    const listItemClass = `sidebar-icon flex items-center text-sm font-light text-[#a3b0b9] px-3 py-3 mb-1 hover:bg-[#0e4d7f] rounded-lg`;

    return (
        <div className={` sidebar-box ${open ? "content-expanded" : "content-collapsed"
            } sidebar mx-auto h-screen fixed top-0 left-0 z-50 sidebar-mobile`} ref={ref}>
            <div className="sidebar-main w-[220px] bg-[#052f4e] h-screen">
                <div className="px-3 pt-4 pb-4 mb-2 flex  justify-between md:justify-center items-center w-full border-b border-[#304654] h-[65px]">

                <div className="logo">
                        <h2><Link to="/" className="text-2xl text-white font-medium text-center">LOGO</Link></h2>
                    </div>
 
                    <div className="md:hidden justify-end flex text-white">
                        <CircleX onClick={toggleSidebar} />
                    </div>
                </div>
                <div className="flex flex-col justify-between" style={{ height: 'calc(100% - 73px)' }}>
                    <div className="px-3">
                        <div className="mt-2 mb-2">
                            <Link to="/compose" className="hover:bg-[#0e4d7f] flex items-center font-light text-sm bg-[#063c66] px-3 py-3 block rounded-lg text-center text-[#fff]"><FilePen className="w-[20px] h-[20px] mr-3 text-[#fff]" />Compose Email</Link>
                        </div>
                        <div className="">
                            {listItems?.map((item, index) => (
                                <Link key={index} to={item.to}
                                    className={`${listItemClass} ${currentRoute.pathname === item.to && 'bg-[#0E4D7F] text-white'}`}
                                >
                                    {item?.icon}
                                    {item?.title}
                                </Link>

                            ))}
                        </div>

                    </div>
                    <div className="logout-btn">
                        <Link to="/login" onClick={handleLogout} className="logout text-sm px-6 py-3 text-[#fff] flex items-center px-2 bg-[#063c66] hover:bg-[#0e4d7f]"><LogOut className=" text-[#fff] w-[16px] h-[16px] mr-3" />Logout</Link>
                    </div>
                     
                </div>
            </div>
        </div >
    );
});

export default Sidebar;
