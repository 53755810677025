import React, { useEffect, useState } from "react";
import Profile1 from "../../images/profile.png";
import { Link } from "react-router-dom";
import { userDatabyid, userProfileUpdate } from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { TbPhotoEdit } from "react-icons/tb";

function Profile() {
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const BASE_URL = "http://localhost:3000/";


  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          const resp = await userDatabyid(userId);
          setUserData(resp?.data);
          setEmail(resp?.data?.user?.email);
          setPassword(resp?.data?.user?.password);
          // setProfilePic(resp?.data?.user?.profilePic);
          setUserProfilePic(resp?.data?.user?.profilepic);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);


const handleProfilePicChange = (event) => {
  const file = event.target.files[0];
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
  const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp']; // Allowed image types
  
  if (file) {
    // Check if the file is an allowed image type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      toast.error('Please select a valid image file (JPEG, PNG, JPG, WEBP).');
      return;
    }

    // Check if the file size exceeds the limit
    if (file.size > MAX_FILE_SIZE) {
      toast.error('Image size must not exceed 5MB.');
      return;
    }

    // If both validations pass, read the file
    const reader = new FileReader();
    reader.onload = () => {
      setProfilePicUrl(reader.result); // Set the URL for the selected image
    };
    reader.readAsDataURL(file);
  }
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
  
    if (profilePicUrl) {
      const filename = profilePicUrl.split('/').pop().split('?')[0]; // Extract filename from URL
      const file = dataURLtoFile(profilePicUrl, filename);
      formData.append("profilePic", file);
    }
  
    if (email !== userData?.user?.email) {
      formData.append("newEmail", email);
    }
  
    if (password !== userData?.user?.password) { // Always check for password change
      formData.append("newPassword", password);
    }
  
    try {
      await userProfileUpdate(formData);
      toast.success("Profile updated successfully");
      // Re-fetch user data after successful update
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        const resp = await userDatabyid(userId);
        setUserData(resp.data);
        setProfilePicUrl(null);  
        window.location.reload();
        setTimeout(() => {
          setLoading(false);
        }, 500);

      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to update profile:", error);
    }
  };

  const currentProfilePicUrl = profilePicUrl || (userProfilePic ? `${userProfilePic}` : Profile1);


  console.log(currentProfilePicUrl,"currentProfilePicUrl1");

  const handleIconClick = () => {
    document.getElementById('profilePicInput').click();
  };
  return (
    <div className="profile-page xl:w-3/5 w-full flex justify-center mx-auto md:mt-10">
      <div className="container mx-auto block lg:grid lg:grid-cols-3 lg:gap-5 gap-3 justify-center flex-wrap">
        <div className="profile-img shadow bg-[#fff] md:p-5 p-3 flex items-center flex-col flex-wrap rounded-md col-span-1 mb-3 md:mb-0">
 
       <div className="innerimg"> 
          <img
            src={currentProfilePicUrl}
            className="w-[150px] mb-2 rounded-full"
            alt="Profile"
          />
          </div>
                <div className="outerimg mb-2">
      <div onClick={handleIconClick} style={{ cursor: 'pointer' }}>
        <TbPhotoEdit className="w-[23px] h-[23px]"/>
      </div>
      <input
        type="file"
        accept="image/*"
        id="profilePicInput"
        onChange={handleProfilePicChange}
        style={{ display: 'none' }}
      />
    </div>
          <p className="text-base md:text-xl font-semibold text-[#063c66] mb-0">
            {userData?.user?.firstName}
          </p>
          <Link
            to="mailto:info@gmail.com"
            className="text-sm font-normal text-gray-700 hover:text-[#063c66]"
          >
            {userData?.user?.email}
          </Link>
        </div>
        <div className="profile-form shadow bg-[#fff] md:p-5 p-3 rounded-md col-span-2">
          <form className="rounded-md" onSubmit={handleSubmit}>
            <h2 className="lg:text-2xl md:text-xl text-xl text-center text-[#063c66] font-semibold mb-5">
              Edit Profile
            </h2>
            <div className="mb-4">
              <input
                className="border rounded w-full text-sm py-3 px-3 text-gray-700 leading-tight focus:outline-none"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <input
                className="border rounded w-full text-sm py-3 px-3 text-gray-700 leading-tight focus:outline-none"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="w-full justify-end flex">
              <button
                className="bg-[#063c66] hover:bg-[#0e4d7f] text-base text-white font-medium py-3 px-6 rounded focus:outline-none"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save' }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;