import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { userLogin } from '../../../Api/ExportApi';
import './login.css';
import { toast } from "react-toastify";
import { useAppContext } from '../../../context/AppContext'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAppContext();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
 

    try {
      const response = await userLogin(email, password);
      console.log(response,"resp")
      login(response.data.user, response.data.token); 
      toast.success(response?.data?.message)
      localStorage.setItem('token', response.data.token);
      if (rememberMe) {
        localStorage.setItem('rememberMe', email);
      } else {
        localStorage.removeItem('rememberMe');
      }
      navigate('/');
    } catch (error) {
      console.log(error,"login error");
      setError(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event) => {
 
    setRememberMe(event.target.checked);
  };

  return (
    <div className="login max-auto h-screen flex items-center p-4">
      <div className="container mx-auto">
        <div className="login-form xl:w-[30%] lg:w-[50%] w-[100%] w-full mx-auto">
          <form
            className="bg-[#00000078] lg:p-[50px] md:p-[30px] p-[20px] rounded-md"
            onSubmit={handleSubmit}
          >
            <h2 className="lg:text-3xl md:text-2xl text-xl text-center text-[#fff] font-semibold mb-3">Login</h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <div className="mb-3">
              <label className="block text-white text-base font-medium mb-2" htmlFor="email-login">
                Email
              </label>
              <input
                className="bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm leading-tight focus:outline-none"
                id="email-login"
                type="email"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="block text-white text-base font-medium mb-2" htmlFor="password-login">
                Password
              </label>
              <input
                className="bg-[transparent] text-white border rounded w-full text-sm py-3 px-3 text-gray-700 leading-tight focus:outline-none"
                id="password-login"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex items-center justify-between mb-4">
 
            <div className="text-sm text-white leading-tight flex items-center">
        
         
                <input
                  //className="remember bg-transparent border border-white rounded text-sm py-3 mr-2 px-3 focus:outline-none"
                  id="remember"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="remember" className="ml-2">Remember Me</label>
              </div>
              <div>
                <Link to="/forgot-password" className="text-sm text-white leading-tight hover:text-[#063c66]">Forgot Password?</Link>
              </div>
            </div>
            <div>
              <button
                className="bg-[#063c66] hover:bg-[#0e4d7f] mx-auto w-full text-base text-white font-medium py-3 px-6 rounded focus:outline-none"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Login'}
              </button>
              <p className="text-sm text-white text-center mt-2 mb-0">
                Don't have an account? <Link to="/register" className="font-medium text-[#fff] underline">Register</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;