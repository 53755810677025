import React, { useState, useEffect } from 'react';
import { AiOutlineFileSearch } from "react-icons/ai";
import './register.css';
import Verificationpopup from '../../../Components/verificationpopup';
import { userRegister } from '../../../Api/ExportApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Register = () => {
    const [showModal, setShowModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [domain, setDomain] = useState('');
    const [email, setEmail] = useState('');
    const [alternativeEmail, setAlternativeEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (firstName && lastName && domain) {
            setEmail(`${firstName}${lastName}@${domain}`);
        }
    }, [firstName, lastName, domain]);

    useEffect(() => {
        if (isSubmitted) {
            let validationErrors = {};

            if (password && confirmPassword && password !== confirmPassword) {
                validationErrors.confirmPassword = 'Passwords do not match';
            } else if (!password) {
                validationErrors.password = 'Password is required';
            } else if (!confirmPassword) {
                validationErrors.confirmPassword = 'Confirm password is required';
            } else {
                validationErrors.password = '';
                validationErrors.confirmPassword = '';
            }

            if(!validateEmail(email)) validationErrors.email = 'Email format not valid';
            if(!validateEmail(alternativeEmail)) validationErrors.alternativeEmail = 'Alternative Email format not valid';

            setErrors(prevErrors => ({
                ...prevErrors,
                ...validationErrors
            }));
        }
    }, [password, confirmPassword, isSubmitted]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        let validationErrors = {};

        if (!firstName) validationErrors.firstName = 'First name is required';
        if (!lastName) validationErrors.lastName = 'Last name is required';
        if (!domain) validationErrors.domain = 'Domain name is required';
        if (!password) validationErrors.password = 'Password is required';
        if (!confirmPassword) validationErrors.confirmPassword = 'Confirm password is required';
        if (!alternativeEmail) validationErrors.confirmPassword = 'Alternative email is required';
        if (password !== confirmPassword) validationErrors.confirmPassword = 'Passwords do not match';

        if(!validateEmail(email)) validationErrors.email = 'Email format not valid';
        if(!validateEmail(alternativeEmail)) validationErrors.alternativeEmail = 'Alternative Email format not valid';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const response = await userRegister({ firstName, lastName, domain, email, alternativeEmail, password });
            if (response?.data?.message) {
                toast.success('Registration successful');
                navigate('/login');
            }
        } catch (error) {
            toast.error('Registration failed');
        }
    };

    return (
        <div className="register max-auto h-screen flex items-center p-4">
            <div className="container mx-auto">
                <div className="register-form lg:w-6/12 md:w-10/12 w-[100%] w-full mx-auto">
                    <form className="bg-[#00000078] xl:p-6 md:p-5 p-4 rounded-md" onSubmit={handleRegister}>
                        <h2 className="lg:text-3xl md:text-2xl text-xl text-center text-white font-semibold mb-3">Register Here</h2>
                        <div className="mb-4">
                            <input
                                className={`text-white bg-[transparent] border rounded w-full py-3 px-3 text-sm text-gray-700 leading-tight focus:outline-none ${errors.firstName ? 'border-red-500' : ''}`}
                                id="username"
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                            {errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                className={`text-white bg-[transparent] border rounded w-full py-3 px-3 text-sm text-gray-700 leading-tight focus:outline-none ${errors.lastName ? 'border-red-500' : ''}`}
                                id="lastname"
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                            {errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName}</p>}
                        </div>
                        <div className="mb-4">
                            <div className="relative mt-2">
                                <input
                                    type="text"
                                    name="domain"
                                    id="domain"
                                    className={`text-white bg-[transparent] border rounded w-full py-3 px-3 text-sm text-gray-700 leading-tight focus:outline-none ${errors.domain ? 'border-red-500' : ''}`}
                                    placeholder="Domain Name"
                                    value={domain}
                                    onChange={(e) => setDomain(e.target.value)}
                                    required
                                />
                                <div className="absolute inset-y-0 right-[1px] flex items-center">
                                    <button
                                        onClick={() => setShowModal(true)}
                                        className="border border-[#063c66] hover:border-[#063c66] bg-[#063c66] hover:bg-[#063c66] text-base text-white font-medium py-3 px-6 leading-tight rounded-r focus:outline-none"
                                        type="button"
                                    >
                                        <AiOutlineFileSearch />
                                    </button>
                                </div>
                            </div>
                            {errors.domain && <p className="text-red-500 text-xs italic">{errors.domain}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                className={`bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm text-gray-700 leading-tight focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
                                id="email"
                                type="email"
                                placeholder="Email"
                                value={email}
                                readOnly
                                required
                            />
                              {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                className={`bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm text-gray-700 leading-tight focus:outline-none ${errors.email ? 'border-red-500' : ''}`}
                                id="alternative_email"
                                type="email"
                                placeholder="Alternative Email"
                                value={alternativeEmail}
                                onChange={(e) => setAlternativeEmail(e.target.value)}
                                required
                            />
                            {errors.alternativeEmail && <p className="text-red-500 text-xs italic">{errors.alternativeEmail}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                className={`bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm text-gray-700 leading-tight focus:outline-none ${errors.password ? 'border-red-500' : ''}`}
                                id="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                        </div>
                        <div className="mb-4">
                            <input
                                className={`bg-[transparent] text-white border rounded w-full text-sm py-3 px-3 text-gray-700 mb-3 leading-tight focus:outline-none ${errors.confirmPassword ? 'border-red-500' : ''}`}
                                id="confirmpassword"
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            {errors.confirmPassword && <p className="text-red-500 text-xs italic">{errors.confirmPassword}</p>}
                        </div>
                        <div>
                            <button
                                className="bg-[#063c66] hover:bg-[#0e4d7f] mx-auto w-full text-base text-white font-medium py-3 px-6 rounded focus:outline-none"
                                type="submit"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                    {showModal && <Verificationpopup closeModel={() => setShowModal(false)} />}
                </div>
            </div>
        </div>
    );
};

export default Register;
