import React, { useEffect, useRef, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import { toast } from "react-toastify";
import {
  markAllRead,
  multiEmailRead,
  multiEmailsStarred,
  multiEmailsStarRemove,
  multiEmailUnread,
} from "../../Api/ExportApi";
import { Mail, MailOpen, Star } from "lucide-react";

const MarkAllRead = ({
  emails,
  refetchEmails,
  currentPage,
  selectAllCheckbox,
  notShowStarrActions,
  selectedItems,
  userId,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [emailsWithStar, setEmailsWithStar] = useState(0);
  const [emailsWithStarIds, setEmailsWithStarIds] = useState([]);
  const [readEmails, setReadEmails] = useState(0);
  const [readEmailsIds, setReadEmailsIds] = useState([]);
  const [unReadEmails, setUnreadEmails] = useState(0);
  const [unreadEmailsIds, setUnreadEmailsIds] = useState([]);

  const dropdownRefs = useRef({});

  useEffect(() => {
    const selectedEmailIds = new Set(selectedItems);

    const stats = {
      starredEmailsCount: 0,
      starredEmailIds: [],
      readEmailsCount: 0,
      readEmailIds: [],
      unreadEmailsCount: 0,
      unreadEmailIds: [],
    };

    emails.forEach((email) => {
      if (selectedEmailIds.has(email._id)) {
        if (email.starredBy.includes(userId)) {
          stats.starredEmailsCount += 1;
          stats.starredEmailIds.push(email._id);
        }
        if (email.readBy.includes(userId)) {
          stats.readEmailsCount += 1;
          stats.readEmailIds.push(email._id);
        } else {
          stats.unreadEmailsCount += 1;
          stats.unreadEmailIds.push(email._id);
        }
      }
    });

    setEmailsWithStar(stats.starredEmailsCount);
    setEmailsWithStarIds(stats.starredEmailIds);
    setReadEmails(stats.readEmailsCount);
    setReadEmailsIds(stats.readEmailIds);
    setUnreadEmails(stats.unreadEmailsCount);
    setUnreadEmailsIds(stats.unreadEmailIds);

    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current &&
        !Object.values(dropdownRefs.current).some(
          (ref) => ref && ref.contains(event.target)
        )
      ) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emails, selectedItems, userId]);


  const handleMarkAllRead = async () => {
    try {
      const unreadMailsId = [];
      emails?.filter((item) => {
        if (!item.readBy.includes(userId)) {
          unreadMailsId.push(item._id);
        }
      });

      if (unreadMailsId.length > 0) {
        const resp = await markAllRead(unreadMailsId);

        if (resp.status === 200) {
          setOpenDropdown(false);
          toast.success("Emails marked as read successfully.");
          refetchEmails(currentPage);
        }
      } else {
        toast.error("No unread email found.");
      }
    } catch (error) {
      setOpenDropdown(false);
      toast.error("Failed to mark emails as read.");
      console.error("Failed to mark emails as read:", error);
    }
  };

  const handleRemoveMultiStarred = async () => {
    setOpenDropdown(false);
    const resp = await multiEmailsStarRemove(emailsWithStarIds);

    if (resp.status === 200) {
      toast.success("Emails unstarred successfully");
      refetchEmails(currentPage);
    }
  };

  const handleMultiEmailStarred = async () => {
    setOpenDropdown(false);
    const resp = await multiEmailsStarred(selectedItems);

    if (resp.status === 200) {
      toast.success("Emails starred successfully");
      refetchEmails(currentPage);
    }
  };

  const handleMultiEmailUnread = async () => {
    setOpenDropdown(false);
    const resp = await multiEmailUnread(readEmailsIds);

    if (resp.status === 200) {
      toast.success("Emails marked as unread");
      refetchEmails(currentPage);
    }
  };
  
  const handleMultiEmailRead = async () => {
    setOpenDropdown(false);
    const resp = await multiEmailRead(unreadEmailsIds);

    if (resp.status === 200) {
      toast.success("Emails marked as read");
      refetchEmails(currentPage);
    }
  };

  return (
    <>
      <button onClick={() => setOpenDropdown(!openDropdown)}>
        <MdMoreVert className="w-[20px] h-[20px] text-black" />
      </button>

      {openDropdown && (
        <div
          ref={(el) => (dropdownRefs.current[123] = el)}
          className="absolute left-16 z-10 top-10 mt-2 w-fit text-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            <li
              className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
              onClick={(event) => {
                event.stopPropagation();
                handleMarkAllRead();
              }}
            >
              <MailOpen className="w-[18px] h-[18px] text-black mr-2" /> Mark
              all as read
            </li>
            {selectedItems.length > 1 && !notShowStarrActions && (
              <>
                {emailsWithStar !== emails?.length &&
                  emailsWithStar !== selectedItems.length && (
                    <li
                      className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                      onClick={() => handleMultiEmailStarred()}
                    >
                      <Star className="w-[18px] h-[18px] text-black mr-2 fill-black" />
                      Add Star
                    </li>
                  )}
                {emailsWithStar > 0 && (
                  <li
                    className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                    onClick={() => handleRemoveMultiStarred()}
                  >
                    <Star className="w-[18px] h-[18px] text-black mr-2" />
                    Remove Star
                  </li>
                )}
              </>
            )}
            {selectedItems.length > 1 && (
              <>
                {readEmails > 0 && (
                  <li
                    className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                    onClick={() => handleMultiEmailUnread()}
                  >
                    <Mail className="w-[18px] h-[18px] text-black mr-2" />
                    Mark as unread
                  </li>
                )}

                {unReadEmails > 0 && <li
                  className="rounded-t flex items-center cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 whitespace-no-wrap"
                  onClick={() => handleMultiEmailRead()}
                >
                  <MailOpen className="w-[18px] h-[18px] text-black mr-2" />
                  Mark as read
                </li>}
              </>
            )}

            {selectedItems.length <= 1 && <li
                    className="flex h-[70px] w-[200px] text-wrap text-center text-gray-400 items-center text-sm border-t-[1px] bg-white py-2 px-4 whitespace-no-wrap"
                  >
                    Select multiple emails to see more actions
                  </li>}
          </div>
        </div>
      )}
    </>
  );
};

export default MarkAllRead;
