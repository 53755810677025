import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { forgotPassword, resetPassword, verifyToken } from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { Loader2 } from "lucide-react";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    const getVerifyToken = async () => {
      try {
        const response = await verifyToken(token);
        
        if (response.data?.valid) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setIsValidToken(false);
      }
    };

    if (token) {
        getVerifyToken();
    } else {
      setIsValidToken(false);
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    try {

      const res = await resetPassword(token, password);

      if(res.status === 200){
        toast.success('Password reset successfully');
        setTimeout(() => {
            navigate('/login');
            setLoading(false);
        }, 1000);
      }
      
    } catch (error) {
      toast.error(error.response?.data);
      setLoading(false);
      console.log(error);
      
    }

  };

  if (!isValidToken) {
    return (
      <div className="login max-auto h-screen flex items-center p-4 ">
        <div className="container mx-auto bg-[#00000078] p-5">
          <div className="login-form lg:w-[30%] md:w-6/12 w-full mx-auto flex items-center">
            <h2 className="lg:text-3xl md:text-2xl text-xl text-center text-[#fff] font-semibold">Invalid or Expired Token</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login max-auto h-screen flex items-center p-4">
      <div className="container mx-auto">
        <div className="login-form lg:w-[30%] md:w-6/12 w-full mx-auto">
          <form className="bg-[#00000078] md:px-[50px] md:py-[50px] p-5 rounded-md">
            <h2 className="lg:text-3xl md:text-2xl text-xl text-center text-[#fff] font-semibold mb-5">
              Reset Password
            </h2>
            <div className="mb-3">
              <label
                className="block text-white text-base font-medium mb-2"
                htmlFor="password"
              >
                New Password
              </label>
              <input
                className="bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm leading-tight focus:outline-none"
                id="password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError("");
                }}
                placeholder="Enter Your Password"
                required
              />
            </div>
            <div className="mb-3">
              <label
                className="block text-white text-base font-medium mb-2"
                htmlFor="confirm-password"
              >
                Confirm Password
              </label>
              <input
                className="bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm leading-tight focus:outline-none"
                id="confirm-password"
                type="password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setError("");
                }}
                placeholder="Confirm Your Password"
                required
              />
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}{" "}
            <div>
              <button
                className={` mx-auto w-full text-base text-white font-medium py-3 px-6 rounded focus:outline-none
                ${
                  !password.trim() || !confirmPassword.trim()
                    ? "bg-[#1b5f92]"
                    : "bg-[#063c66] hover:bg-[#0e4d7f]"
                }`}
                type="button"
                onClick={handleSubmit}
                disabled={!password.trim() || !confirmPassword.trim() || loading}
              >
                {loading ? <div className='flex items-center justify-center'><Loader2 className='animate-spin'/> Loading...</div> : 'Reset Password'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
