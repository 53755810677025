import React, { useEffect, useState, useRef } from "react";
import Profile from "../../images/profile.png";
import { Link } from "react-router-dom";
import { userDatabyid, getSearchEmails,createEntire } from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import { CircleChevronLeft, CircleChevronRight, LoaderCircle, X } from "lucide-react";

const Topbar = ({ toggleSidebar, open }) => {
  const [userData, setUserData] = useState(null);
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");  
  const [searchResults, setSearchResults] = useState([]);  
  const [showResults, setShowResults] = useState(false);  
  const [authtoken, setAuthToken] = useState();
  const [loading, setLoading] = useState(false);
  const searchRef = useRef(null);  

  const BASE_URL = "http://localhost:3000/";

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          const resp = await userDatabyid(userId);
          setUserData(resp?.data);
          setUserProfilePic(resp?.data?.user?.profilepic);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = async (e) => {
    if (e.key === "Enter" || e.type === "click") {
      setLoading(true);
      try {
        const params = {
          search: searchQuery,
          userId: userData?.user?._id 
        };
        const response = await getSearchEmails(params);
        
        setSearchResults(response.data.result);
        setShowResults(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error("Error searching emails:", err);
      }
    }
  };

 
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};


const fetchToken = async () => {

  try {
    const applicationId ="sitesgpt";
    const secret ="249a79f7905e44c7d358ce2cf2ab5b929d45e1262dc4386005e6471519440909";
    

    const resp = await createEntire(applicationId,secret);
 
    setAuthToken(resp?.data?.authToken)
    // return data.authToken;
  } catch (error) {
    console.error('Error fetching token:', error);
  }
};

useEffect(() => {
  fetchToken();
}, [])


const launchEntri = () => {
  // Ensure `entri` is available
  if (typeof window.entri === 'undefined') {
    console.error('Entri SDK not loaded.');
    return;
  }

  if (!authtoken) {
    console.error('Auth token is not available');
    return;
  }

  const config = {
    applicationId: "sitesgpt", // Replace with your actual applicationId
    token: authtoken,
    dnsRecords: [
      {
        type: "CNAME",
        host: "www",
        value: "m.test.com",
        ttl: 300,
      },
      {
        type: "TXT",
        host: "@",
        value: "sample-txt-record",
        ttl: 300,
      },
      {
        type: "MX",
        host: "host",
        value: "mailhost1.example.com",
        priority: 10,
        ttl: 300,
      },
    ],
  };
  

  // Use the global entri object to show the modal
  window.entri.showEntri(config);
};

  return (
    <div className="topbar w-full bg-[#fff]">
      <div className="flex items-center justify-between border-b border-[#eee] mx-auto py-2.5 h-[65px] px-4 gap-2">
        <div className="w-2/4 flex items-center md:gap-5 gap-2">
          <div className="dashbord-menu" onClick={toggleSidebar}>
            {open ? (
              <CircleChevronLeft className="text-[30px] cursor-pointer text-[#063c66] hover:text-[#0e4d7f]" />
            ) : (
              <CircleChevronRight className="text-[30px] cursor-pointer text-[#063c66] hover:text-[#0e4d7f]" />
            )}
          </div>
          <div className="w-screen md:w-full">
            <div className="relative flex w-full items-stretch" ref={searchRef}>
              <input
                type="text"
                className="relative m-0 block flex-auto rounded-full border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[5px] text-sm font-normal leading-[1.6] outline-none transition duration-200 ease-in-out"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="button-addon2"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearch}
              />
              {searchQuery.length > 0 && <X className="w-[20px] h-[20px] absolute right-10 top-[6px] cursor-pointer z-30" onClick={() => setSearchQuery("")}/>}
              <span
                className="searchicon border-0 input-group-text flex items-center whitespace-nowrap rounded-r px-3 py-1.5 text-center text-base font-normal text-neutral-400 absolute right-0  top-[1px]"
                id="basic-addon2"
                onClick={handleSearch}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-[18px] w-[18px] cursor-pointer"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              <>
              {loading ? <div className="search-results bg-white shadow-md mt-2 p-4 rounded-lg w-full flex justify-center"><LoaderCircle className="animate-spin" />Loading...</div> : <>
              {showResults && (
                <div className="search-results bg-white shadow-md mt-2 p-4 rounded-lg w-full">
                  {searchResults?.length > 0 ? (
                    <ul className="list-disc pl-5">
                      {searchResults?.map((result, index) => (
                        
                        <li key={index} className="mb-1 text-sm text-gray-700 ">
                          <Link to={`/inbox-single/${result?._id}`}>
                            {result?.subject || "No Subject"}
                          </Link>
                          <Link to={`/inbox-single/${result?._id}`}>
                          <p className="text-xs text-gray-500">   {stripHtmlTags(result?.body)}</p>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div>No results found.</div>
                  )}
                </div>
              )}
              </>
            }
              </>
            </div>
          </div>
        </div>
        <div>
        {/* <button type="button" onClick={launchEntri}>
    Launch Entri
</button> */}
        </div>
        <div className="user-profile flex items-center justify-end gap-2">
          <div className="">
            <Link to="/profile">
              {userProfilePic ? (
                <img
                  src={`${userProfilePic}`}
                  className="w-[50px] rounded-full border-4 border-[#063c6626]"
                  alt="profile"
                />
              ) : (
                <img
                  src={Profile}
                  className="w-[50px] rounded-full border-4 border-[#063c6626]"
                  alt="profile"
                />
              )}
            </Link>
          </div>
          <div className="md:block hidden">
            <Link to="/profile">
              <p className="text-sm font-medium text-[#063c66] mb-0">
                {userData?.user?.firstName}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
