import React from "react";

export default function Verificationpopup({ closeModel }) {

    return (
        <>
            <div
                className="p-10 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto max-w-xl">
                    {/*content*/}
                    <div className="p-6 rounded-md relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between rounded-t">
                            <h3 className="md:text-2xl text-xl font-semibold text-[#063c66]">
                                Sent Verification Link
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={closeModel}
                            >
                                <span className="bg-transparent text-gray-700 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto">
                            <p className="my-4 text-gray-700 text-base leading-relaxed">
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.
                            </p>

                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end rounded-b">
                            <button
                                className="bg-[#063c66] hover:bg-[#063c66] text-base text-white font-medium py-3 px-10 rounded focus:outline-none" type="button"

                            >
                                Send
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

        </>
    );
}