import { ChevronLeft, ChevronRight, RotateCw } from "lucide-react";
import React, { useEffect, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { MetroSpinner } from "react-spinners-kit";
import {
  userDatabyid,
  getTrashEmails,
  permanentDelete,
  permanentDeleteAllEmails,
  emptyTrash
} from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MarkAllRead from "../../Components/Ui/MarkAllRead";
import useCheckboxHandler from "../../hooks/useCheckboxHandler";

// Function to strip HTML tags
const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const Trash = () => {
  const [userid, setUserId] = useState();
  const [trashemails, setTrashEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emaildeleted, setEmailDeleted] = useState(null);

  const { selectAllCheckbox, selectedItems, setSelectAllCheckbox, setSelectedItems, handleCheckboxClick } = useCheckboxHandler(trashemails);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;
          const resp = await userDatabyid(userId);
          setUserId(resp?.data?.user?._id);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  const fetchTrashEmails = async (page) => {
    if (userid) {
      setLoading(true);
      try {
        const resp = await getTrashEmails(userid, page);
        setTrashEmails(resp?.data?.trashedEmails || []);
        // setTotalItems(resp?.data?.trashedEmails?.length || 0);
        
        setTotalItems(resp?.data?.total || 0);
        setItemPerPage(resp?.data?.limit || 0);
        setSelectAllCheckbox(false);
        setSelectedItems([]);
      } catch (err) {
        console.log('www');
        console.log("Error fetching trash emails:", err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTrashEmails(currentPage);
  }, [userid, currentPage]);

  const handleDelete = (emailId) => {
    setEmailDeleted(emailId);
    setIsModalOpen(true);
  };

  const handlePermanentlyDelete = async () => {
    if (!emaildeleted) {
      toast.error("No email selected for deletion.");
      return;
    }

    try {
      // Call the API to permanently delete the email
      const resp = await permanentDelete(emaildeleted);
      
      if (resp?.data?.message === "Email permanently deleted") {
        toast.success("Email permanently deleted");
      } else {
        toast.error("Failed to delete email.");
      }

      // Close the modal and refresh the email list
      setIsModalOpen(false);
      fetchTrashEmails(currentPage);
    } catch (error) {
      toast.error("Failed to delete email.");
      console.error("Error deleting email:", error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await permanentDeleteAllEmails(selectedItems);
      fetchTrashEmails(currentPage);
      toast.success("All trashed emails have been deleted successfully");
    } catch (error) {
      toast.error("Failed to delete email.");
      console.error("Error deleting email:", error);
    }
  };

  
  const handleEmptyTrash = async () => {
    try {
      const trashEmailsIds = [];
      trashemails.forEach(item => {
        trashEmailsIds.push(item._id);
      });
      
      await emptyTrash(trashEmailsIds);
      fetchTrashEmails(currentPage);
      toast.success("All trashed emails have been deleted successfully");
    } catch (error) {
      toast.error("Failed to delete email.");
      console.error("Error deleting email:", error);
    }
  };

  const handleRefresh = () => {
    fetchTrashEmails(currentPage);
  };

  return (
    <>
      <div className="trash">
        <div className="mx-auto">
          <div className="trash-main w-full mx-auto">
            <div className="bg-[#fff] rounded-md shadow mb-2">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>
                      <div className="flex items-center gap-2 relative">
                        <div className="dropdown inline-block relative">
                          <button className="text-gray-700 font-semibold py-2 rounded inline-flex items-center">
                            <span className="mr-1">
                              {/* <MdOutlineCheckBoxOutlineBlank className="text-xl text-black" /> */}
                              <input
                                checked={selectAllCheckbox}
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckboxClick(e, "toggle")
                                }
                              />
                            </span>
                            <svg
                              className="fill-current h-4 w-4 text-black"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </button>
                          <ul className="dropdown-menu absolute text-gray-700 pt-1 hidden group-hover:block">
                            <li
                              className="rounded-t cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                              onClick={(e) => handleCheckboxClick(e, "all")}
                            >
                              All
                            </li>
                            <li
                              className="bg-white text-sm cursor-pointer font-medium hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                              onClick={(e) => handleCheckboxClick(e, "none")}
                            >
                              None
                            </li>
                            {/* <li
                              className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                              onClick={(e) => handleCheckboxClick(e, "read")}
                            >
                              Read
                            </li>
                            <li
                              className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                              onClick={(e) => handleCheckboxClick(e, "unread")}
                            >
                              Unread
                            </li> */}
                          </ul>
                        </div>
                        <button onClick={handleRefresh}>
                          <RotateCw className="w-[20px] h-[20px] text-black" />
                        </button>
                        <MarkAllRead  emails={trashemails} currentPage={currentPage} refetchEmails={fetchTrashEmails} selectedItems={selectedItems} notShowStarrActions={true}/>
                        {selectedItems?.length > 1 && (
                          <button
                            className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                            onClick={handleDeleteAll}
                          >
                            Delete Forever
                          </button>
                        )}
                        {trashemails?.length > 0 && <button
                            className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                            onClick={handleEmptyTrash}
                          >
                            Empty Trash Now
                        </button>}
                      </div>
                    </td>
                    <td>
                      <div className="flex justify-end gap-2 mt-2">
                        <div>
                          <p className="text-base text-black">{`${
                            (currentPage - 1) * itemsPerPage + 1
                          }-${Math.min(
                            currentPage * itemsPerPage,
                            totalItems
                          )} of ${totalItems}`}</p>
                        </div>
                        <div>
                          <button
                            onClick={() =>
                              setCurrentPage(Math.max(currentPage - 1, 1))
                            }
                            disabled={currentPage === 1}
                          >
                            <ChevronLeft className="w-[20px] h-[20px] text-black" />
                          </button>
                          <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={totalItems <= currentPage * itemsPerPage}
                          >
                            <ChevronRight className="w-[20px] h-[20px] text-black" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="bg-[#fff] rounded-md shadow">
              <table className="w-full">
                <tbody>
                  {isLoading ? (
                     <tr style={{ textAlign: "center" }}>
                     <td
                       colSpan="3"
                       className="loader-icon"
                       style={{
                         textAlign: "center",
                         padding: "80px 0",
                         display: "inline-block",
                       }}
                     >
                       <MetroSpinner size={50} color="#000000" />
                     </td>
                   </tr>
                  ) : trashemails.length > 0 ? (
                    trashemails.map((email, index) => {
                      const plainTextBody = stripHtmlTags(email?.body);
                      return (
                        <tr
                          key={index}
                          className={`rounded-t-md border-b hover:bg-[#063c6626] ${
                            email.read ? "bg-[#063c6626]" : "bg-[#f5f5f5]"
                          }`}
                        >
                          <td className="w-[2%]">
                            <span>
                              <input
                                type="checkbox"
                                checked={
                                  selectAllCheckbox ||
                                  selectedItems.includes(email?._id)
                                }
                                 name="checkbox"
                                onChange={(e) =>
                                  handleCheckboxClick(e, "single", email?._id)
                                }
                              />
                            </span>
                          </td>
                          <td className="md:px-0 px-[15px] w-[50%]">
                            <p className="text-black font-medium text-base break-all">
                              {email.subject}
                            </p>
                            <p className="text-grey-700 text-sm break-all">
                              {plainTextBody}
                            </p>
                          </td>
                          <td className="w-[15%]">
                            <p className="flex items-center gap-2 justify-end text-[#063c66] font-medium">
                            {moment(email?.createdAt).format("MMM DD")}
                              <button onClick={() => handleDelete(email?._id)}>
                                <IoTrashOutline className="w-[18px] h-[18px] text-gray-700" />
                              </button>
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center py-4">
                        No emails found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to permanently delete this email?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => handlePermanentlyDelete(emaildeleted)}
            >
              Yes
            </Button>
            <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Trash;
