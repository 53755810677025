import React, { useEffect, useRef } from "react";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";

function Layout({ children }) {
    const [open, setOpen] = useState(true);
    const location = useLocation();
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setOpen(window.innerWidth > 767 ? true : false);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && window.innerWidth <= 767) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [sidebarRef]);

    if (location?.pathname === "/register" || location?.pathname === "/login" || location?.pathname === "/forgot-password" || location?.pathname.includes('/reset-password/')) {
        return children;
    }
    
    return (
        <div className="flex bg-[#f0f9ff]">

            {open && <Sidebar ref={sidebarRef} open={open} toggleSidebar={() => setOpen(!open)} />}
            {/* content-right */}
            <div className={`content flex items-center h-screen flex-col w-full ${open ? 'ml-[220px]' : 'ml-0'} layout-mobile`}>
                <Topbar open={open} toggleSidebar={() => setOpen(!open)} />

                <div className="lg:p-[30px] p-[15px] w-full h-screen bg-[#f0f9ff] overflow">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;
