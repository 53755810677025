import {  Mail, Send, Trash2, Star } from "lucide-react";
import { MdOutlineArchive } from "react-icons/md";
const iconClass = `w-[16px] h-[16px] mr-3 text-[#a3b0b9]`;

export const listItems = [
  // {
  //     title: "Compose Email",
  //     icon: <FilePen className={iconClass} />,
  // },
  {
    title: "Inbox",
    to: "/inbox",
    icon: <Mail className={iconClass} />,
  },
  {
    title: "Starred",
    to: "/starred",
    icon: <Star className={iconClass} />,
  },
  {
    title: "Archived",
    to: "/archived",
    icon: <MdOutlineArchive className={iconClass} />,
  },
  {
    title: "Sent Items",
    to: "/sentitem",
    icon: <Send className={iconClass} />,
  },
  {
    title: "Trash",
    to: "/trash",
    icon: <Trash2 className={iconClass} />,
  },
];
