import "./App.css";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import Login from "./Components/Auth/login";
import Register from "./Components/Auth/register";
import Profile from "./Pages/Profile";
import Compose from "./Pages/ComposeEmail";
import Inbox from "./Pages/Inbox";
import Sentitem from "./Pages/SentItem";
import Trash from "./Pages/Trash";
import InboxSingle from "./Pages/InboxSingle";
import { AppProvider } from "./context/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./Components/PrivateRoutes/PrivateRoute";
import PublicRoute from "./Components/PrivateRoutes/PublicRoute";
import Starred from "./Pages/Starred";
import Archived from "./Pages/Archived";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";


const routes = [
  { path: "/register", element: <Register />, isPublic: true },
  { path: "/login", element: <Login />, isPublic: true },
  { path: "/forgot-password", element: <ForgotPassword />, isPublic: true },
  { path: "/reset-password/:token", element: <ResetPassword />, isPublic: true },
  { path: "/", element: <Inbox /> },
  { path: "/profile", element: <Profile /> },
  { path: "/compose", element: <Compose /> },
  { path: "/inbox", element: <Inbox /> },
  { path: "/starred", element: <Starred /> },
  { path: "/archived", element: <Archived /> },
  { path: "/sentitem", element: <Sentitem /> },
  { path: "/trash", element: <Trash /> },
  { path: "/inbox-single/:id", element: <InboxSingle /> },
];

function App() {
  return (
    <AppProvider>
      <ToastContainer />
      <BrowserRouter>
        <Layout>
          <Routes>
            {routes.map((route) => {
              const RouteComponent = route.isPublic ? PublicRoute : PrivateRoute;
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <RouteComponent>
                      {route.element}
                    </RouteComponent>
                  }
                />
              );
            })}
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
