import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../Api/ExportApi';
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';

const ForgotPassword = () => {

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError("");
    if (!value) {
      setEmailError("Email is required");
    }
  };


  const handleForgotPassword = async () => {

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
    } else {
      setLoading(true);
      setEmailError("");
      const res = await forgotPassword(email);

      if(res.status === 200){
          toast.success('Password reset link sent successfully to your alternative email');
          setEmail("");
          setLoading(false);
      }else{
        toast.error("Something went wrong");
        setLoading(false);
      }
    }
  }

  return (
    <div className="login max-auto h-screen flex items-center p-4">
      <div className="container mx-auto">
        <div className="login-form lg:w-[30%] md:w-6/12 w-full mx-auto">
          <form
            className="bg-[#00000078] md:px-[50px] md:py-[50px] p-5 rounded-md"
          >
            <h2 className="lg:text-3xl md:text-2xl text-xl text-center text-[#fff] font-semibold mb-3">Forgot Password</h2>
            <div className="mb-3">
              <label className="block text-white text-base font-medium mb-2" htmlFor="email-login">
                Email
              </label>
              <input
                className="bg-[transparent] text-white border rounded w-full py-3 px-3 text-sm leading-tight focus:outline-none"
                id="email-login"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter Your Email"
                required
              />
             {emailError && <div className="text-red-500 mt-2">{emailError}</div>}
            </div>
            
            <div>
              <button
                className={`mx-auto w-full text-base text-white font-medium py-3 px-6 rounded focus:outline-none ${
                  !email.trim()
                    ? "bg-[#1b5f92]"
                    : "bg-[#063c66] hover:bg-[#0e4d7f]"
                }`}
                type="button"
                onClick={handleForgotPassword}
                disabled={!email.trim() || loading}
              >
                {loading ? <div className='flex items-center justify-center'><Loader2 className='animate-spin'/> Loading...</div> : 'Forgot Password'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;