import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, RotateCw } from "lucide-react";
import { FaRegStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  userDatabyid,
  toggleFavoriteStatus,
  moveAllEmailToTrash,
  getStarredMails,
} from "../../Api/ExportApi";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { MetroSpinner } from "react-spinners-kit";
import { toast } from "react-toastify";
import MarkAllRead from "../../Components/Ui/MarkAllRead";
import useCheckboxHandler from "../../hooks/useCheckboxHandler";

const Starred = () => {
  const [inboxid, setInboxid] = useState(null);
  const [starredEmails, setStarredEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [favoriteEmails, setFavoriteEmails] = useState({});
  const [mailslurpemail, setMailslurpEmail] = useState();
  const [userId, setUserId] = useState(null);

  const { selectAllCheckbox, selectedItems, setSelectAllCheckbox, setSelectedItems, handleCheckboxClick } = useCheckboxHandler(starredEmails);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          setUserId(userId);
          const resp = await userDatabyid(userId);
          setInboxid(resp?.data?.user?.inboxId);
          setMailslurpEmail(resp?.data?.user?.mailslurpEmail);
        } catch (err) {
          console.error("Error fetching user data:", err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    fetchStarredEmails(currentPage);
  }, [currentPage]);

  const fetchStarredEmails = async (page) => {
    setLoading(true);
    try {
      const resp = await getStarredMails(page);

      setStarredEmails(resp.data.emails);
      setTotalItems(resp?.data?.total || 0);
      setItemPerPage(resp?.data?.limit);
      setSelectAllCheckbox(false);
      setSelectedItems([]);
    } catch (err) {
      // toast.error("Failed to fetch emails");
      console.error("Error fetching incoming emails:", err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFavoriteStatus = async (emailId) => {
    try {
      await toggleFavoriteStatus(emailId);
      setFavoriteEmails((prevFavorites) => ({
        ...prevFavorites,
        [emailId]: !prevFavorites[emailId],
      }));
      toast.success("Email favorite status updated");
      fetchStarredEmails(currentPage);
    } catch (error) {
      toast.error("Failed to update favorite status");
      console.error("Error updating favorite status:", error);
    }
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleRefresh = () => {
    fetchStarredEmails(currentPage);
  };

  const handleDeleteAll = async () => {
    try {
      await moveAllEmailToTrash([...selectedItems]);
      if(selectedItems.length === starredEmails.length){
        if(currentPage > 1){
          fetchStarredEmails(currentPage - 1);
        }
      }else{
        fetchStarredEmails(currentPage);
      }
    } catch (error) {
      toast.error("Failed to delete email.");
      console.error("Error deleting email:", error);
    }
  };

  return (
    <div className="inbox">
      <div className="container mx-auto">
        <div className="inbox-main w-full mx-auto">
          <div className="bg-[#fff] rounded-md shadow mb-2">
            <table className="w-full">
              <thead>
                <tr>
                  <td>
                    <div className="flex items-center gap-2 relative">
                      <div className="dropdown inline-block relative">
                        <button className="text-gray-700 font-semibold py-2 rounded inline-flex items-center">
                          <span className="mr-1">
                            {/* <MdOutlineCheckBoxOutlineBlank className="text-xl text-black" /> */}
                            <input
                              checked={selectAllCheckbox}
                              type="checkbox"
                              onChange={(e) => handleCheckboxClick(e, "toggle")}
                            />
                          </span>
                          <svg
                            className="fill-current h-4 w-4 text-black"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </button>
                        <ul className="dropdown-menu absolute text-gray-700 pt-1 hidden group-hover:block">
                          <li
                            className="rounded-t cursor-pointer text-sm font-medium bg-white hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "all")}
                          >
                            All
                          </li>
                          <li
                            className="bg-white text-sm cursor-pointer font-medium hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "none")}
                          >
                            None
                          </li>
                          <li
                            className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "read", null, userId)}
                          >
                            Read
                          </li>
                          <li
                            className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "unread", null, userId)}
                          >
                            Unread
                          </li>
                          <li
                            className="rounded-b text-sm cursor-pointer bg-white font-medium text-gray-700 hover:!bg-[#dae2e8] py-2 px-4 block whitespace-no-wrap"
                            onClick={(e) => handleCheckboxClick(e, "starred", null, userId)}
                          >
                            Starred
                        </li>
                        </ul>
                      </div>

                      <button onClick={handleRefresh}>
                        <RotateCw className="w-[20px] h-[20px] text-black" />
                      </button>
                      <MarkAllRead  emails={starredEmails} currentPage={currentPage} refetchEmails={fetchStarredEmails} selectedItems={selectedItems} userId={userId} selectAllCheckbox={selectAllCheckbox}/>
                      {selectedItems?.length > 1 && (
                        <button
                          className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                          onClick={handleDeleteAll}
                        >
                          Trash All
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex justify-end gap-2 mt-2">
                      <div>
                        <p className="text-base text-black">
                          {startItem}-{endItem} of {totalItems}
                        </p>
                      </div>
                      <div>
                        <button
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <ChevronLeft className="w-[20px] h-[20px] text-black" />
                        </button>
                        <button
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <ChevronRight className="w-[20px] h-[20px] text-black" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
          </div>

          <div className="bg-[#fff] rounded-md shadow">
            <table className="w-full">
              <tbody>
                {isLoading ? (
                  <tr style={{ textAlign: "center" }}>
                    <td
                      colSpan="3"
                      className="loader-icon"
                      style={{
                        textAlign: "center",
                        padding: "80px 0",
                        display: "inline-block",
                      }}
                    >
                      <MetroSpinner size={50} color="#000000" />
                    </td>
                  </tr>
                ) : (
                  <>
                    {starredEmails?.length > 0 ? (
                      starredEmails?.map((email) => {
                        const plainTextBody = stripHtmlTags(email?.body);
                        return (
                          <tr
                            key={email._id}
                            className={`hover:bg-[#063c6626] cursor-pointer border-b-[1px] border-[#80808024] ${
                              email.readBy.includes(userId) ? "bg-[#063c6626]" : ""
                            }`}
                          >
                            <td className="w-[2%]">
                              <span>
                                <input
                                  type="checkbox"
                                  checked={
                                    selectAllCheckbox ||
                                    selectedItems.includes(email?._id)
                                  }
                                   name="checkbox"
                                  onChange={(e) =>
                                    handleCheckboxClick(e, "single", email?._id)
                                  }
                                />
                              </span>
                            </td>
                            <div className="flex justify-between flex-wrap" onClick={() =>
                              navigate(`/inbox-single/${email?._id}`)
                            }>
                            <td className="md:px-0 px-[15px] md:w-[50%] w-[100%]">
                              <p className="text-black font-medium text-base mb-0 break-all">
                                {email?.subject}
                              </p>
                              <p className="text-grey-700 text-sm break-all mb-0">
                                {plainTextBody}
                              </p>
                            </td>

                            <td className="md:w-[30%] w-[100%]">
                              <p className="flex items-center gap-2 md:justify-end text-[#063c66] font-medium">
                                {moment(email?.createdAt).format("MMM DD")}

                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleToggleFavoriteStatus(email?._id);
                                  }}
                                  className={`w-[18px] h-[18px] text-yellow-500`}
                                >
                                  <FaRegStar />
                                </button>
                              </p>
                            </td>
                            </div>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          No emails found
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Starred;
